<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <!--begin::Form Wizard-->
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="form">
        <!--begin::Step 1-->
        <div data-wizard-type="step-content" data-wizard-state="current">
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('institutions.institutions_name') }}</label>
                <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <input
                    class="form-control"
                    :class="classes"
                    :name="$t('institutions.institutions_name')"
                    type="text"
                    v-model="formData.name"
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.country') }}</label>
                <validation-provider rules="required" :name="$t('commons.country')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <multiselect
                      v-model="selectedCountry"
                      :options="countries"
                      :internal-search="true"
                      :allow-empty="false"
                      open-direction="bottom"
                      @input="onChangeCountry"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='code'
                      label='code'
                      :placeholder="$t('commons.choose')"
                      :class="classes"
                  >
                    <template slot='singleLabel' slot-scope='{ option }'>{{ option.code.toUpperCase() }}</template>
                    <template slot='option' slot-scope='{ option }'>{{ option.code.toUpperCase() }}</template>
                    <template slot='noOptions'>{{ 'List is empty' }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('institutions.type') }}</label>
                <validation-provider rules="required" :name="$t('institutions.type')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <multiselect
                      v-model="selectedType"
                      :options="institutionTypes"
                      :searchable="false"
                      :allow-empty="false"
                      open-direction="bottom"
                      @input="onChangeType"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='label'
                      label='label'
                      :placeholder="$t('commons.choose')"
                      :class="classes"
                  >
                    <template slot='singleLabel' slot-scope='{ option }'>{{ $t(`service.${option.label}`) }}</template>
                    <template slot='option' slot-scope='{ option }'>{{ $t(`service.${option.label}`) }}</template>
                    <template slot='noOptions'>{{ 'List is empty' }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.mobile_phone') }}</label>
                <validation-provider rules="required" :name="$t('commons.mobile_phone')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <div class="input-group" :class="classes">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="la la-phone"></i
                      ></span>
                    </div>
                    <input
                      type="number"
                      value=""
                      id="phoneValid"
                      minlength="10"
                      placeholder="905xxxxxxxxx"
                      aria-describedby="phoneHelp"
                      class="form-control"
                      :class="classes"
                      v-model="formData.phone"
                    />
                  </div>
                  <small id="phoneHelp" class="form-text text-muted">{{ $t('commons.enter_with_area_code') }}</small>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.e_mail') }}</label>
                <validation-provider rules="required|email" :name="$t('commons.e_mail')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <div class="input-group" :class="classes">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="la la-at"></i
                      ></span>
                    </div>
                    <input
                      type="email"
                      class="form-control"
                      :class="classes"
                      :placeholder="$t('commons.e_mail')"
                      v-model="formData.email"
                    />
                  </div>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.password') }}</label>
                <validation-provider rules="required" :name="$t('commons.password')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <input
                    class="form-control"
                    :class="classes"
                    type="text"
                    v-model="formData.password"
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.status') }}</label>
                <validation-provider rules="required" :name="$t('commons.status')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <multiselect
                    v-model="selectedStatus"
                    :options="allStatus"
                    :searchable="false"
                    :allow-empty="false"
                    open-direction="bottom"
                    @input="onChangeStatus"
                    deselect-label=''
                    select-label=''
                    selected-label=''
                    track-by='name'
                    label='name'
                    :placeholder="$t('commons.choosen_status')"
                    :class="classes"
                  >
                      <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }}</template>
                      <template slot='option' slot-scope='{ option }'>{{ option.name }}</template>
                      <template slot='noOptions'>{{ 'List is empty' }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row" v-if="isDistributor">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.file') }}</label>
                <validation-provider
                    rules="required|img_required"
                    :name="$t('commons.file')" v-slot="{ classes, errors, validate}" class="col-6" v-if="!isLoading">
                <div class="row m-1">
                    <span class="btn col-12 btn-outline-primary round btn-file" :class="classes">
                      {{ $t('commons.browseFile') }}
                      <input
                        id="institution_file"
                        ref="institution_file"
                        name="institution_file"
                        @change="validate"
                        type="file"
                        class="custom-input"
                        accept="image/*"
                        :class="classes" multiple
                      />
                    </span>
                    <span class="error__message">{{ errors[0] }}</span>
                </div>
                <div class="row ml-1">
                    <span v-if="$refs.institution_file && $refs.institution_file.files[0]">
                      <span class="fileName" v-for="(item, index) in $refs.institution_file.files" :key="'file-' + index">
                        {{ item.name }}
                      </span>
                    </span>
                </div>
              </validation-provider>
              </div>
              <div class="form-group row" v-if="isDistributor">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('institutions.signature_date') }}</label>
                <validation-provider rules="required" :name="$t('institutions.signature_date')" v-slot="{ classes, errors }" class="col-6">
                  <div class="input-group date" :class="classes">
                    <input type="text" class="form-control" id="kt_datepicker_2" readonly="readonly" ref="date" :class="classes" :placeholder="$t('commons.select_date')" v-model="formData.signature_date">
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i class="la la-calendar-check-o"></i>
                      </span>
                    </div>
                  </div>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
        <!--begin::Actions-->
        <div class="d-flex justify-content-end border-top mt-5 pt-10">
          <form-submit-btn
            :btnClass="'btn btn-success font-weight-bolder text-uppercase px-9 py-4'"
            :isSubmitting="isSubmitting"
            @click="handleSubmit(createInstitution)"
            :text="$t('commons.submit')"
          />
        </div>
        <!--end::Actions-->
      </form>
      </ValidationObserver>
      <!--end::Form Wizard-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { extend } from 'vee-validate';
import i18n from '@/config/_i18n'
import { Skeleton } from 'vue-loading-skeleton';


import $ from "jquery";
import 'bootstrap-datepicker';

const all_status = [
  { value: 1, name: i18n.t("institutions.active_status") },
  { value: 0, name: i18n.t("institutions.passive_status") }
]
extend('img_required', {
  validate(imgUrl) {
    return imgUrl !== '';
  },
  message() {
    return 'Image is required!';
  }
});
export default {
  name: "NewInstitution",
  data() {
    return {
      formData: {
        name: "",
        country_id: "",
        type: "",
        phone: "",
        email: "",
        password: "",
        status: "",
        signature_date: null
      },
      isSubmitting: false,
      selectedCountry: null,
      selectedType: null,
      allStatus: all_status,
      selectedStatus: "",
      isDistributor: null
    };
  },
  components: {
    Multiselect,
    Skeleton
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.institutions.isLoading,
      countries: (state) => state.institutions.countries,
      institutionTypes: (state) => state.institutions.institutionTypes,
    }),
  },
  methods: {
    ...mapActions("institutions", [
      "GET_INSTITUTIONS_DATA",
      "POST_INSTITUTIONS",
    ]),
    createInstitution() {
      let sendData = new FormData();
      if(this.isDistributor) {
        let files = this.$refs.institution_file.files;
        for(let i=0; i<files.length; i++) {
          sendData.append(`attachments[${i}]`, files[i]);
        }
        this.formData.signature_date = this.$refs.date.value.replaceAll('/', '-');
      }
      Object.entries(this.formData).forEach(([key, value]) => {
        sendData.append(key, value);
      });
      // console.log(this.formData)
      this.isSubmitting = true;
      this.POST_INSTITUTIONS(sendData).then(() => {
        this.$refs.form.reset();
      })
      .catch(() => {})
      .finally(() => {
        this.isSubmitting = false
      })
    },
    resetForm() {
      this.formData = {
        name: "",
        country_id: "",
        type: "",
        phone: "",
        email: "",
        password: "",
        status: ""
      }
      this.selectedCountry = null;
      this.selectedType = null;
      this.selectedStatus = null;
    },
    onChangeCountry() {
      this.formData.country_id = this.selectedCountry.id
    },
    onChangeType() {
      this.formData.type = this.selectedType.value
    },
    onChangeStatus() {
      this.formData.status = this.selectedStatus.value
    }
  },
  mounted() {
    
  },
  created() {
    const vm = this;
    this.GET_INSTITUTIONS_DATA({ type: "create" }).then(() => {
      this.isDistributor = this.institutionTypes.some(val => val.id == 20)
      this.$nextTick(function (){
        $('#kt_datepicker_2').datepicker({
            buttonClasses: ' btn',
            applyClass: 'btn-primary',
            cancelClass: 'btn-secondary',
            orientation: "top left",
        }, function(start) {
            $('#kt_datepicker_2 .form-control').val(start.format('YYYY-MM-DD'));
        }).on('changeDate', function () {
          vm.formData.signature_date = vm.$refs.date.value;
        });
      })
    })
  }
};
</script>
<style scoped>
.is-invalid ~ .error__message {
  display: block;
}

[hidden] {
  display: none !important;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
</style>